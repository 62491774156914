import React, { Component } from "react";
import styled from "styled-components";
import projectData from "../Data/ProjectData";

const Box = styled.div`
  background-color: #eff1f3;
  border: 3px solid lightgrey;
  border-radius: 2px;
  margin: 5px 10px;
`;

const Container = styled.a`
  margin: 10px;
  background-color: white;
  border: 2px solid lightgrey;
  border-radius: 2px;
  width: 300px;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  scroll-snap-align: start;

  &:hover {
    background-color: #ddd;
    opacity: 0.7;
  }
`;

const Title = styled.h3`
  padding: 8px;
  font-weight: 700;
`;

const Row = styled.h3`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
`;

class Projects extends Component {
  render() {
    console.log(projectData[0].img);
    const projectList = projectData.map(project => (
      <Container
        href={project.link}
        target="_blank"
        rel="noopener noreferrer"
        key={project.name}
      >
        <img src={project.img} alt="project cover" style={{ width: "100%" }} />
        <h3>{project.name}</h3>
      </Container>
    ));

    return (
      <Box>
        <Title>Portfolio</Title>
        <Row>{projectList}</Row>
      </Box>
    );
  }
}

export default Projects;
