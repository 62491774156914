import corona from "../assets/img/corona.gif";
import worldbank from "../assets/img/worldbank.gif";
import burger from "../assets/img/burger.gif";
import yelpcamp from "../assets/img/yelpcamp.gif";

const projectData = [
  {
    name: "Corona Tracker",
    desc:
      "App to track the progression of Corona virus cases, deaths and recoveries",
    img: corona,
    link: "https://covid-19-tracker.net/"
  },
  {
    name: "World Bank Dashboard",
    desc:
      "App to track the progression of Corona virus cases, deaths and recoveries",
    img: worldbank,
    link: "https://pedantic-hamilton-ac48dc.netlify.com/"
  },
  {
    name: "Burger Builder",
    desc:
      "App to track the progression of Corona virus cases, deaths and recoveries",
    img: burger,
    link: "https://happy-hoover-450933.netlify.com/"
  },
  {
    name: "YelpCamp",
    desc:
      "App to track the progression of Corona virus cases, deaths and recoveries",
    img: yelpcamp,
    link: "https://safe-everglades-71475.herokuapp.com/"
  }
];

export default projectData;
