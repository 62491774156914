import React from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 8px;
  background-color: ${props => (props.isDragging ? "#FFC300" : "white")};
  transition: 0.2s ease;
`;

export default class Task extends React.Component {
  render() {
    return (
      <Draggable draggableId={this.props.task.id} index={this.props.index}>
        {(provided, snapshot) => (
          <Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
          >
            {this.props.task.link ? (
              <div className="task-container">
                <a
                  className="links"
                  href={this.props.task.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {this.props.task.content}
                </a>
                <div className="link-dot"></div>
              </div>
            ) : (
              this.props.task.content
            )}
            {this.props.task.progress && (
              <div className="star-container">
                {this.props.task.progress.map(star => {
                  if (star) return <span className="fa fa-star checked"></span>;
                  else return <span className="fa fa-star"></span>;
                })}
              </div>
            )}
          </Container>
        )}
      </Draggable>
    );
  }
}
