import React from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import Task from './task';

const Container = styled.div`
    margin: 8px;
    border: 3px solid lightgrey;
    background-color: #eff1f3;
    border-radius: 2px;
    width: 300px;
    height: 93%;
    overflow-y: hidden;

    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    scroll-snap-align: start;
`;
const Title = styled.h3`
    padding: 8px;
    font-weight: 700;
`;
const TaskList = styled.div`
    padding: 8px;
    transition: background-color 0.2s ease;
    background-color: ${props => (props.isDraggingOver ? '#93FFEC' : '#eff1f3')};
    flex-grow: 1;
    min-height: 100px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    ::-webkit-scrollbar:vertical {
        width: 11px;
    }
    ::-webkit-scrollbar:horizontal {
        height: 11px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
        background-color: rgba(0, 0, 0, .2);
    }
`;

export default class Column extends React.Component {
    render() {
        return (
            <Container>
                <Title>{this.props.column.title}</Title>
                <Droppable droppableId={this.props.column.id} >
                    {(provided, snapshot) => (
                        <TaskList
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            isDraggingOver={snapshot.isDraggingOver}
                        >
                            {this.props.tasks.map((task, index) => (
                                <Task key={task.id} task={task} index={index} />
                            ))}
                            {provided.placeholder}
                        </TaskList>
                    )}
                </Droppable>
            </Container>
        );
    }
}