const initialData = {
  tasks: {
    "task-1": {
      id: "task-1",
      content: "Head of Engineering - Infarm",
      link: "https://www.infarm.com/"
    },
    "task-2": {
      id: "task-2",
      content: "Industrial Designer - Invivo Industrial Design",
      link: "https://www.invivodesign.de/"
    },
    "task-3": { id: "task-3", content: "Founder and Engineer - CIRC Design" },
    "task-4": { id: "task-4", content: "Web Designer - Freelance" },
    "task-5": {
      id: "task-5",
      content: "Web Developer Bootcamp - Udemy",
      link: "https://www.udemy.com/course/the-web-developer-bootcamp/"
    },
    "task-6": {
      id: "task-6",
      content: "Advanced Web Developer Bootcamp - Udemy",
      link: "https://www.udemy.com/course/the-advanced-web-developer-bootcamp/"
    },
    "task-7": {
      id: "task-7",
      content: "Harvard CS50 Introduction to Computer Science - EDX",
      link: "https://www.edx.org/course/cs50s-introduction-to-computer-science"
    },
    "task-8": {
      id: "task-8",
      content:
        "React The Complete Guide (incl Hooks, React Router, Redux) - Udemy",
      link: "https://www.udemy.com/course/react-the-complete-guide-incl-redux/"
    },
    "task-9": {
      id: "task-9",
      content: "Build World Bank Data Visualization Dashboard in React"
    },
    "task-10": { id: "task-10", content: "Build portfolio" },
    "task-11": { id: "task-11", content: "Get a Developer job" },
    "task-12": { id: "task-12", content: "Master Web Development" },
    "task-13": { id: "task-13", content: "" },
    "task-14": {
      id: "task-14",
      content: "Build cryptocurrency tracker and charting tool"
    },
    "task-15": { id: "task-15", content: "Build an app I would use every day" },
    "task-16": { id: "task-16", content: "Build personal developer website" },
    "task-17": {
      id: "task-17",
      content: "HTML & CSS",
      progress: [1, 1, 1, 1, 0]
    },
    "task-18": {
      id: "task-18",
      content: "YelpCamp",
      link: "https://safe-everglades-71475.herokuapp.com/"
    },
    "task-19": {
      id: "task-19",
      content: "Circles - Press any key",
      link: "https://wi0.github.io/Circles/"
    },
    "task-20": {
      id: "task-20",
      content: "Color Game",
      link: "https://wi0.github.io/colorGame/"
    },
    "task-21": {
      id: "task-21",
      content: "Cryptocurrency Chart with D3",
      link: "https://wi0.github.io/CryptoChart/"
    },
    "task-22": {
      id: "task-22",
      content: "To Do App with React",
      link: "https://dreamy-newton-c485a6.netlify.com/"
    },
    "task-23": {
      id: "task-23",
      content: "To Do App basic",
      link: "https://wi0.github.io/Todo-List/"
    },
    "task-24": {
      id: "task-24",
      content: "Burger Builder App with React",
      link: "https://happy-hoover-450933.netlify.com/"
    },
    "task-25": {
      id: "task-25",
      content: "World Bank Data Dashboard",
      link: "https://pedantic-hamilton-ac48dc.netlify.com/"
    },
    "task-26": {
      id: "task-26",
      content: "Build React Native app for Android and iOS"
    },
    "task-27": { id: "task-27", content: "Commit to open source projects" },
    "task-28": { id: "task-28", content: "Build tab tool for Chrome" },
    "task-29": {
      id: "task-29",
      content: "Professional path - Frontend Masters",
      link: "https://frontendmasters.com/learn/professional/"
    },
    "task-30": {
      id: "task-30",
      content: "React path - Frontend Masters",
      link: "https://frontendmasters.com/learn/react/"
    },
    "task-31": {
      id: "task-31",
      content: "Fullstack path - Frontend Masters",
      link: "https://frontendmasters.com/learn/fullstack/"
    },
    "task-32": {
      id: "task-32",
      content: "Expert path - Frontend Masters",
      link: "https://frontendmasters.com/learn/advanced/"
    },
    "task-33": {
      id: "task-33",
      content: "Git & Github",
      progress: [1, 1, 1, 0, 0]
    },
    "task-34": {
      id: "task-34",
      content: "Figma, Wireframing",
      progress: [1, 1, 1, 0, 0]
    },
    "task-35": {
      id: "task-35",
      content: "Kanban method, Github projects, Trello & Asana",
      progress: [1, 1, 1, 1, 0]
    },
    "task-36": {
      id: "task-36",
      content: "JavaScript",
      progress: [1, 1, 1, 0, 0]
    },
    "task-37": {
      id: "task-37",
      content: "React",
      progress: [1, 1, 1, 0, 0]
    }
  },

  columns: {
    "column-1": {
      id: "column-1",
      title: "To do",
      taskIds: [
        "task-11",
        "task-12",
        "task-14",
        "task-15",
        "task-26",
        "task-27",
        "task-28"
      ]
    },
    "column-2": {
      id: "column-2",
      title: "In progress",
      taskIds: ["task-9", "task-10", "task-30", "task-31", "task-32"]
    },
    "column-3": {
      id: "column-3",
      title: "Done",
      taskIds: [
        "task-36",
        "task-37",
        "task-33",
        "task-17",
        "task-34",
        "task-35",
        "task-16"
      ]
    },
    "column-4": {
      id: "column-4",
      title: "Completed courses",
      taskIds: ["task-29", "task-8", "task-5", "task-6", "task-7"]
    },
    "column-5": {
      id: "column-5",
      title: "Background",
      taskIds: ["task-1", "task-2", "task-3", "task-4"]
    }
  },
  //Reordering of columns
  columnOrder: ["column-1", "column-2", "column-3", "column-4", "column-5"]
};

// "task-25",
//   "task-24",
//   "task-18",
//   "task-21",
//   "task-22",
//   "task-19",
//   "task-20",
//   "task-23";

export default initialData;
