import React from "react";
import "./App.css";
import Profile from "../assets/img/profile.jpg";
import Container from "./Container";
import Projects from "../components/Projects";
// import '@atlaskit/css-reset';

function App() {
  return (
    <div className="App">
      <div className="top-container">
        <img src={Profile} alt="A profile of Duncan Williams" />
        <div className="text-container flex-column">
          <h1>
            <span>Name: </span>Duncan Williams
          </h1>
          <h3>
            <span>Profession: </span>Web Developer
          </h3>
          <h3>
            <span>Background: </span>Mechanical Engineer and Designer
          </h3>
          <h3>
            <span>Status: </span>Looking for work
          </h3>
          <h3>
            <span>Location: </span>Greece. Remote.
          </h3>
          <div className="social-container">
            <a
              href="https://twitter.com/williamsxcode"
              target="_blank"
              rel="noopener noreferrer"
              className="btn facebook"
            >
              <i className="fa fa-twitter"></i>
            </a>
            <a
              href="https://www.linkedin.com/in/duncan-williams2020/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn linkedin"
            >
              <i className="fa fa-linkedin"></i>
            </a>
            <a
              href="https://github.com/Wi0"
              target="_blank"
              rel="noopener noreferrer"
              className="btn github"
            >
              <i className="fa fa-github"></i>
            </a>
            <a href="mailto:duncanlw@gmail.com" className="btn email">
              <i className="fa fa-envelope"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="bottom-container">
        <Container />
        <div className="portfolio-container">
          <Projects />
        </div>
      </div>
    </div>
  );
}

export default App;
